// const config = require("../../../studio/sanity.json");
const config = {
  api: {
    projectId: "3hmxm3df",
    dataset: "production",
  }
}

module.exports = {
  projectId: config.api.projectId,
  dataset: config.api.dataset,
};
